import React from 'react';
import { createUseStyles } from 'react-jss';
import { Paper } from '../utility/paper';
import { CheckCircle } from 'react-bootstrap-icons';
import { ContactButton } from '../layout/contactButton';

const useStyles = createUseStyles((theme) => ({
  card: {
    textAlign: 'left',
    padding: ['var(--size-2x)', 'var(--size-4x)', 'var(--size-2x)'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    textAlign: 'center',
    marginBottom: 'var(--size-2x)',
    '& svg': {
      height: 'var(--size-2x)',
      width: 'var(--size-2x)',
      display: 'inline-block',
      marginRight: 'calc(var(--size-2x) / 3)'
    }
  },
  title: {
    display: 'inline-block',
    fontSize: 'var(--font-size-xl)',
    fontWeight: 600,
    verticalAlign: 'middle',
    marginBottom: 0
  },
  subtitle: {
    marginTop: 'var(--font-size-md)',
    fontSize: 'var(--font-size-lg)',
    fontWeight: 600
  },
  content: {},
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0
  },
  bullet: {
    '& path': {
      fill: theme.yellow
    },
    marginRight: 5
  },
  bulletText: {
    marginBottom: 'var(--font-size-xs)',
    fontSize: 'var(--font-size-md)'
  },
  button: {
    marginTop: 'var(--font-size-xl)'
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    card: {
      padding: 'var(--font-size-md)'
    }
  }
}));

export function OfferCard({ cardTitle, cardSubTitle, bulletPoints, icon }) {
  const classes = useStyles();
  return (
    <Paper className={classes.card} as="section">
      <div>
        <header className={classes.header}>
          {icon}
          <h2 className={classes.title}>{cardTitle}</h2>
          <p className={classes.subtitle}>{cardSubTitle}</p>
        </header>
        <ul className={classes.list}>
          {bulletPoints.map((bp, i) => (
            <li className={classes.bulletText} key={i}>
              <CheckCircle className={classes.bullet} />
              {bp}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <ContactButton additionalClassName={classes.button} />
      </div>
    </Paper>
  );
}
