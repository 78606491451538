import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { OfferCard } from './offerCard';
import BusinessEnglish from '../../assets/business-english.svg';
import EdukacjaZaGranica from '../../assets/edukacja-za-granica.svg';
import NaukaWymowy from '../../assets/nauka-wymowy.svg';
import AngielskiWIt from '../../assets/angielski-w-it.svg';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {
    paddingTop: 'var(--size-2x)',
    paddingBottom: 'var(--size-4x)',
    ...theme.minSidePadding,
    textAlign: 'center',
    background: theme.lightGrey,
    maxWidth: '100%'
  },
  grid: {
    margin: [0, 'auto'],
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1vmax',
    justifyContent: 'center',
    maxWidth: 'var(--layout-width)'
  },
  heading: {
    fontSize: 'var(--font-size-xxl)'
  },
  subheading: {
    fontSize: 'var(--font-size-lg)'
  },
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    grid: {
      gridTemplateColumns: '1fr',
      maxWidth: 750
    }
  }
}));

const icons = {
  'angielski-w-it': <AngielskiWIt />,
  'business-english': <BusinessEnglish />,
  'edukacja-za-granica': <EdukacjaZaGranica />,
  'nauka-wymowy': <NaukaWymowy />
};

export const Offer = ({ containerStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      cards: allCardsYaml(sort: { fields: [position], order: ASC }) {
        nodes {
          bulletPoints
          cardSubTitle
          cardTitle
          icon
        }
      }
    }
  `);
  const classes = useStyles();
  return (
    <div className={classes.container} style={containerStyle}>
      <header>
        <h1 className={classes.heading}>Nasza oferta</h1>
        <p className={classes.subheading}>
          Chcesz zdobyć pierwszą pracę? Jesteś już profesjonalistą z
          doświadczeniem?
        </p>
      </header>
      <div className={classNames(classes.grid)}>
        {data.cards.nodes.map(({ icon, cardTitle, ...other }) => (
          <OfferCard {...other} key={cardTitle} icon={icons[icon]} />
        ))}
      </div>
    </div>
  );
};
